import { Waypoint } from "react-waypoint";
import { useSpring, animated } from '@react-spring/web';
import { useState } from "react";

export default function FadeIn({
  children,
  delay = 500,
  slideY = 24,
  ...restProps
}) {
  const [inView, setInView] = useState(false);

  const transition = useSpring({
    delay,
    to: {
      y: !inView ? slideY : 0,
      opacity: !inView ? 0 : 1,
    },
  });

  return (
    <Waypoint onEnter={() => setInView(true)}>
      <animated.div style={transition} {...restProps}>
        {children}
      </animated.div>
    </Waypoint>
  );
};

