import styled from 'styled-components';

const BaseLink = styled.a`
  text-decoration: underline;
  transition: color 0.5s ease;

  &.inverted {
    color: white;
  }

  &:hover {
    color: var(--color-tertiary);
  }
`;

export default function Link({
  children,
  className,
  inverted,
  tab = false,
  ...restProps
}) {
  restProps.target = tab ? '_blank' : '_self';
  const classNames = `${inverted ? 'inverted' : ''} ${className}`;
  return (
    <BaseLink {...restProps} className={classNames}>
      {children}
    </BaseLink>
  )
};
