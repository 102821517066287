import styled from 'styled-components';

const Title3 = styled.h3`
  color: var(--color-secondary);
  font-size: 28px;
  line-height: 1.2;
  margin: 0;
  margin: 20px 0;
  text-align: center;
`;

export default Title3;
