import NavigationBar from 'layout/NavigationBar';
import React from 'react';
import SectionAbout from 'sections/SectionAbout';
import SectionCareer from 'sections/SectionCareer';
import SectionContact from 'sections/SectionContact';
import SectionHome from 'sections/SectionHome';
import SectionSkills from 'sections/SectionSkills';

export default function HomePage() {
  return (
    <>
      <NavigationBar />
      <SectionHome />
      <SectionCareer even />
      <SectionSkills />
      <SectionAbout even />
      <SectionContact />
    </>
  );
}
