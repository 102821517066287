import styled from 'styled-components';

const Title2 = styled.h2`
  color: var(--color-primary);
  font-size: 36px;
  line-height: 1.4;
  margin: 0 0 40px 0;
`;

export default Title2;
