import React from 'react';
import { Waypoint } from "react-waypoint";
import styled from 'styled-components';
import { useNavigation } from 'layout/NavigationContext';

const SectionContainer = styled.section`
  align-items: center;
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  position: relative;

  &.even {
    background: var(--color-background-alternative);
  }

  &:last-child {
    padding: 0;
  }
`;

const MidSectionDetector = styled.section`
  bottom: 50%;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
`;

export default function Section({
  children,
  even = false,
  name,
  ...restProps
}) {
  const sectionReference = React.useRef();
  const { dispatch } = useNavigation();
  const classNames = even ? 'even' : '';

  const handleVisible = () => {
    dispatch({ type: 'SET_ACTIVE', payload: name });
  };

  return (
    <SectionContainer {...restProps} ref={sectionReference} id={name} className={classNames}>
      <Waypoint onEnter={handleVisible}>
        <MidSectionDetector />
      </Waypoint>
      {children}
    </SectionContainer>
  );
}
