import React from 'react';

import timeline from 'vendors/timeline/src/js/timeline.js';
import 'vendors/timeline/src/scss/timeline.scss';

function Item({ children }) {
  return (
    <div className="timeline__item">
      <div className="timeline__content">
        {children}
      </div>
    </div>
  );
}

function Root({
  children,
  options = {},
}) {
  const timelineReference = React.useRef(null);

  React.useEffect(() => {
    timeline([timelineReference.current], options);
  }, [options]);

  return (
    <div ref={timelineReference} className="timeline" style={{ width: '100%' }}>
      <div className="timeline__wrap">
        <div className="timeline__items">
          {children}
        </div>
      </div>
    </div>
  );
}

const defaultExport = {
  Root,
  Item,
};

export default defaultExport;
