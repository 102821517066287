import Link from 'components/semantic/Link';
import React from 'react';
import { toast } from 'react-toastify';

export default function ShareButton({
  children,
  ...restProps
}) {
  const copyToClipboard = () => {
    const url = window.location.href;

    navigator.clipboard.writeText(url)
      .then(() => {
        toast.success('Link copied to clipboard!');
      })
      .catch((error) => {
        toast.error('Failed to copy link to clipboard!');
        console.error('Failed to copy link to clipboard!', error);
      });
  }

  const handleClick = (e) => {
    e.preventDefault();

    if (navigator.share) {
      navigator.share({
        text: 'Check out this online resume of Olivier Leclercq!',
        title: 'Olivier Leclercq',
        url: window.location.href,
      })
        .then(() => { })
        .catch(() => {
          copyToClipboard();
        });
    } else {
      copyToClipboard();
    }
  }

  return (
    <Link {...restProps} onClick={handleClick} href="#">
      {children}
    </Link>
  );
}
