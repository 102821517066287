import Image from 'components/semantic/Image';
import React from 'react';
import Stars from 'components/Stars';
import styled from 'styled-components';
import { skillNameToSvgImage } from 'data/skillNameToSvgImage';

const SkillBox = styled.div`
  border-radius: 6px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  margin: 5px 10px;
  padding: 10px;
  text-align: center;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  width: 100px;
`;

const SkillImageBox = styled.div`
  flex-grow: 3;
  height: 100px;
`;

const SkillTextBox = styled.div`
  flex-grow: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Skill({
  level,
  name,
}) {
  const imageUrl = skillNameToSvgImage(name);

  return (
    <SkillBox>
      <SkillContainer>
        {!!imageUrl && (
          <SkillImageBox>
            <Image
              alt={name}
              src={imageUrl}
              style={{ objectFit: 'contain' }}
            />
          </SkillImageBox>
        )}

        <SkillTextBox>
          <Stars value={level} />
        </SkillTextBox>

        <SkillTextBox>
          {name}
        </SkillTextBox>
      </SkillContainer>
    </SkillBox>
  );
}
