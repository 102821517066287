import React from 'react';
import styled from 'styled-components';

const StarsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Star = styled.span`
  filter: drop-shadow(0px 0px 1px var(--color-primary));
  font-size: 1.5rem;
  line-height: 1;
  width: 100%;
`;

export default function Stars({
  count = 5,
  value,
}) {
  return (
    <StarsContainer>
      {Array.from({ length: count }, (_, i) => (
        <Star key={i}>
          {String.fromCharCode(i < value ? 9733 : 9734)}
        </Star>
      ))}
    </StarsContainer>
  );
}
