import Link from 'components/semantic/Link';
import React from 'react';
import styled from 'styled-components';
import { useNavigation } from 'layout/NavigationContext';

const Nav = styled.nav`
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  height: 50px;
  letter-spacing: 2px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const NavLinks = styled.ul`
  display: flex;
  gap: 30px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 15px 0;
  font-size: 1.2em;

  @media (max-width: 768px) {
    gap: 10px;
    font-size: 1em;
  }

  & a {
    text-decoration: none !important;

    &.active {
      text-decoration: underline !important;
    }
  }
`;

function NavigationBarLink({ name }) {
  const { state } = useNavigation();
  const nameUcFirst = name.charAt(0).toUpperCase() + name.slice(1);

  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href').substring(1);
    const targetSection = document.getElementById(targetId);

    window.scrollTo({
      top: targetSection.offsetTop - 50,
      behavior: 'smooth'
    });
  };

  const classNames = state.active === name ? 'active' : '';

  return (
    <li>
      <Link
        className={classNames}
        href={`#${name}`}
        onClick={handleClick}
      >
        {nameUcFirst}
      </Link>
    </li>
  );
}

export default function NavigationBar() {
  return (
    <Nav>
      <NavLinks>
        <NavigationBarLink name="home" />
        <NavigationBarLink name="career" />
        <NavigationBarLink name="skills" />
        <NavigationBarLink name="about" />
        <NavigationBarLink name="contact" />
      </NavLinks>
    </Nav>
  );
}
