import React from 'react';

import './Tabs.scss';

function TabTitle({
  children,
  onClick,
  selected = false,
}) {
  const mainClassNames = `tabs-name${selected ? ' selected' : ''}`;
  return (
    <div className={mainClassNames} onClick={onClick}>
      <div className="tabs-name-text">
        {children}
      </div>
    </div>
  );
}

function TabContent({
  children,
  onClick,
  displayed = false,
}) {
  const mainClassNames = `tab ${displayed ? 'displayed' : 'hidden'}`;
  return (
    <div className={mainClassNames}>
      {children}
    </div>
  );
}

export function Tab({ children, name }) { return null; }

export function Tabs({
  children,
  defaultTab = 0,
}) {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  return (
    <div className="tabs">
      <div className="tabs-names">
        {children.map(({ props }, index) => (
          <TabTitle
            key={props.name}
            onClick={(event) => setSelectedTab(index)}
            selected={index === selectedTab}
          >
            {props.name}
          </TabTitle>
        ))}
      </div>
      <div className="tabs-content">
        {children.map(({ props }, index) => (
          <TabContent
            key={props.name}
            displayed={index === selectedTab}
          >
            {props.children}
          </TabContent>
        ))}
      </div>
    </div>
  );
}

const defaultExport = {
  Tab,
  Tabs,
}

export default defaultExport;
