import Age from 'components/Age';
import Container from 'components/semantic/Container';
import Emphasis from 'components/semantic/Emphasis';
import FadeIn from 'components/animation/FadeIn';
import Quote from 'components/semantic/Quote';
import React from 'react';
import Section from 'components/Section';
import Tag from 'components/Tag';
import { Title2 } from 'components/semantic/Titles';

const birthDate = new Date(1991, 2, 24);
const formattedBirthDate = birthDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

export default function SectionAbout(props) {
  return (
    <FadeIn delay={250}>
      <Section {...props} name="about">
        <Title2>
          About me
        </Title2>

        <Container>
          <FadeIn delay={300}>
            <Emphasis style={{ textAlign: 'center' }}>
              <Age birthDate={birthDate} /> years old
              <br />
              {`Born ${formattedBirthDate}`}
            </Emphasis>
          </FadeIn>

          <FadeIn delay={400}>
            <Quote>
              <Tag>Role-playing games</Tag>
              I play role-playing games for their narrative and creative aspects, as well as for the communication skills they cultivate.
            </Quote>
          </FadeIn>

          <FadeIn delay={500}>
            <Quote>
              <Tag>Board & card games</Tag>
              I play board games and card games for their strategic and resource management elements, as well as for the social interaction they provide.
            </Quote>
          </FadeIn>

          <FadeIn delay={600}>
            <Quote>
              <Tag>TV shows & cinema</Tag>
              I watch TV shows and movies primarily for entertainment, but also to enrich my cultural knowledge and maintain my proficiency in English.
            </Quote>
          </FadeIn>

          <FadeIn delay={700}>
            <Quote>
              <Tag>Sports</Tag>
              To maintain physical activity and good health, I primarily swim, occasionally hike, and sometimes practice parkour.
            </Quote>
          </FadeIn>

          <FadeIn delay={800}>
            <Quote>
              <Tag>Ecological Mindset</Tag>
              I am dedicated to sustainability, actively reducing my ecological footprint through waste minimization, energy conservation, and sustainable transportation. I integrate these eco-friendly values into my personal and professional life.
            </Quote>
          </FadeIn>

          <FadeIn delay={900}>
            <Quote>
              <Tag>Political Awareness</Tag>
              I stay informed about current political issues and engage in discussions to better understand diverse perspectives. This awareness helps me contribute thoughtfully to societal and workplace conversations.
            </Quote>
          </FadeIn>
        </Container>
      </Section>
    </FadeIn>
  );
}
