import angular from '../assets/skills/angular.svg'
import apache2 from '../assets/skills/apache2.svg'
import aws from '../assets/skills/aws.svg'
import azure from '../assets/skills/azure.svg'
import bootstrap from '../assets/skills/bootstrap.svg'
import c from '../assets/skills/c.svg'
import canva from '../assets/skills/canva.svg'
import chatgpt from '../assets/skills/chatgpt.svg'
import cordova from '../assets/skills/cordova.svg'
import cplusplus from '../assets/skills/cplusplus.svg'
import csharp from '../assets/skills/csharp.svg'
import css from '../assets/skills/css.svg'
import discord from '../assets/skills/discord.svg'
import docker from '../assets/skills/docker.svg'
import elasticsearch from '../assets/skills/elasticsearch.svg'
import ffmpeg from '../assets/skills/ffmpeg.svg'
import figma from '../assets/skills/figma.svg'
import firebase from '../assets/skills/firebase.svg'
import flutter from '../assets/skills/flutter.svg'
import gcp from '../assets/skills/gcp.svg'
import gimp from '../assets/skills/gimp.svg'
import git from '../assets/skills/git.svg'
import github from '../assets/skills/github.svg'
import gitlab from '../assets/skills/gitlab.svg'
import go from '../assets/skills/go.svg'
import googleworkspace from '../assets/skills/googleworkspace.svg'
import graphql from '../assets/skills/graphql.svg'
import html from '../assets/skills/html.svg'
import ionic from '../assets/skills/ionic.svg'
import java from '../assets/skills/java.svg'
import javascript from '../assets/skills/javascript.svg'
import jquery from '../assets/skills/jquery.svg'
import jqueryui from '../assets/skills/jqueryui.svg'
import kibana from '../assets/skills/kibana.svg'
import kubernetes from '../assets/skills/kubernetes.svg'
import letsencrypt from '../assets/skills/letsencrypt.svg'
import lxc from '../assets/skills/lxc.svg'
import mariadb from '../assets/skills/mariadb.svg'
import markdown from '../assets/skills/markdown.svg'
import materialui from '../assets/skills/materialui.svg'
import microsoft365 from '../assets/skills/microsoft365.svg'
import microsoftoffice from '../assets/skills/microsoftoffice.svg'
import mongoDB from '../assets/skills/mongoDB.svg'
import mysql from '../assets/skills/mysql.svg'
import nestjs from '../assets/skills/nestjs.svg'
import nextJS from '../assets/skills/nextJS.svg'
import nginx from '../assets/skills/nginx.svg'
import nodejs from '../assets/skills/nodejs.svg'
import notion from '../assets/skills/notion.svg'
import opencv from '../assets/skills/opencv.svg'
import openvpn from '../assets/skills/openvpn.svg'
import php from '../assets/skills/php.svg'
import postgresql from '../assets/skills/postgresql.svg'
import python from '../assets/skills/python.svg'
import react from '../assets/skills/react.svg'
import redis from '../assets/skills/redis.svg'
import redux from '../assets/skills/redux.svg'
import selenium from '../assets/skills/selenium.svg'
import slack from '../assets/skills/slack.svg'
import subversion from '../assets/skills/subversion.svg'
import swift from '../assets/skills/swift.svg'
import symfony from '../assets/skills/symfony.svg'
import tailwind from '../assets/skills/tailwind.svg'
import tensorflow from '../assets/skills/tensorflow.svg'
import terraform from '../assets/skills/terraform.svg'
import typescript from '../assets/skills/typescript.svg'
import unity from '../assets/skills/unity.svg'
import vitejs from '../assets/skills/vitejs.svg'
import vue from '../assets/skills/vue.svg'

export const skillNameToSvgImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case 'angular': return angular;
    case 'apache': case 'apache2': return apache2;
    case 'aws': return aws;
    case 'azure': return azure;
    case 'bootstrap': return bootstrap;
    case 'c#': return csharp;
    case 'c': return c;
    case 'c++': return cplusplus;
    case 'canva': return canva;
    case 'chatgpt': return chatgpt;
    case 'cordova': return cordova;
    case 'css': case 'css3': return css;
    case 'discord': return discord;
    case 'docker': return docker;
    case 'elasticsearch': return elasticsearch;
    case 'ffmpeg': return ffmpeg;
    case 'figma': return figma;
    case 'firebase': return firebase;
    case 'flutter': return flutter;
    case 'gcp': return gcp;
    case 'gimp': return gimp;
    case 'git': return git;
    case 'github': return github;
    case 'gitlab': return gitlab;
    case 'go': return go;
    case 'google workspace': return googleworkspace;
    case 'graphql': return graphql;
    case 'html': case 'html5': return html;
    case 'ionic': return ionic;
    case 'java': return java;
    case 'javascript': return javascript;
    case 'jquery': return jquery;
    case 'jqueryui': return jqueryui;
    case 'kibana': return kibana;
    case 'kubernetes': return kubernetes;
    case 'lets encrypt': return letsencrypt;
    case 'lxc': return lxc;
    case 'mariadb': return mariadb;
    case 'markdown': return markdown;
    case 'materialui': return materialui;
    case 'microsoft 365': return microsoft365;
    case 'microsoft office': return microsoftoffice;
    case 'mongodb': return mongoDB;
    case 'mysql': return mysql;
    case 'nestjs': return nestjs;
    case 'next js': return nextJS;
    case 'nginx': return nginx;
    case 'nodejs': return nodejs;
    case 'notion': return notion;
    case 'opencv': return opencv;
    case 'openvpn': return openvpn;
    case 'php': return php;
    case 'postgresql': return postgresql;
    case 'python': return python;
    case 'react': return react;
    case 'redis': return redis;
    case 'redux': return redux;
    case 'selenium': return selenium;
    case 'slack': return slack;
    case 'subversion': return subversion;
    case 'swift': return swift;
    case 'symfony': return symfony;
    case 'tailwind': return tailwind;
    case 'tensorflow': return tensorflow;
    case 'terraform': return terraform;
    case 'typescript': return typescript;
    case 'unity': return unity;
    case 'vitejs': return vitejs;
    case 'vue': return vue;
    default: break;
  }
}
