import React from 'react';
import styled from 'styled-components';

const PortraitContainer = styled.div`
  border-radius: 50%;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  height: 300px;
  margin: 20px auto;
  overflow: hidden;
  width: 300px;
`;

export default function Portrait({ children, ...restProps }) {
  return (
    <PortraitContainer {...restProps}>
      {children}
    </PortraitContainer>
  );
}
