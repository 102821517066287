import ExperienceCard from 'components/ExperienceCard';
import ExperienceModal from 'components/ExperienceModal';
import React from 'react';

export default function Experience(props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <>
      <ExperienceCard {...props} onClick={() => setIsModalOpen(true)} />
      <ExperienceModal {...props} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
