import Container from 'components/semantic/Container';
import Education from 'components/Education';
import Emphasis from 'components/semantic/Emphasis';
import Experience from 'components/Experience';
import FadeIn from 'components/animation/FadeIn';
import React from 'react';
import Section from 'components/Section';
import Timeline from 'components/Timeline';
import { Title2 } from 'components/semantic/Titles';

import education from 'data/education';
import experience from 'data/experience';

const timelineOptions = {
  mode: 'horizontal',
  visibleItems: 4,
  moveItems: 3,
  forceVerticalMode: 768
};

export default function SectionCareer(props) {
  return (
    <FadeIn delay={250}>
      <Section {...props} name="career">
        <Title2>
          Career
        </Title2>

        <Emphasis>
          Please click on a card to display full details
        </Emphasis>

        <Container>
          <Timeline.Root options={timelineOptions}>
            {experience.map((item, index) => (
              <Timeline.Item key={index}>
                <Experience {...item} />
              </Timeline.Item>
            ))}
            {education.map((item, index) => (
              <Timeline.Item key={index}>
                <Education {...item} />
              </Timeline.Item>
            ))}
          </Timeline.Root>
        </Container>
      </Section>
    </FadeIn>
  );
}
