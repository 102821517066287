import Container from 'components/semantic/Container';
import FadeIn from 'components/animation/FadeIn';
import Image from 'components/semantic/Image';
import Portrait from 'components/Portrait';
import Quote from 'components/semantic/Quote';
import React from 'react';
import Section from 'components/Section';
import Subtitle from 'components/semantic/Subtitle';
import { Title1 } from 'components/semantic/Titles';

export default function SectionHome(props) {
  return (
    <FadeIn delay={200}>
      <Section {...props} name="home">
        <FadeIn delay={300}>
          <Title1>
            Olivier Leclercq
          </Title1>
        </FadeIn>

        <FadeIn delay={400}>
          <Subtitle>
            Full Stack Developer
          </Subtitle>
        </FadeIn>

        <FadeIn delay={700}>
          <Portrait>
            <Image
              alt="Portrait of Olivier Leclercq"
              src="images/olivierleclercq-small.webp"
            />
          </Portrait>
        </FadeIn>

        <Container>
          <FadeIn delay={800}>
            <Quote>
              With a strong grasp of business logic from diverse project experiences, I deliver tailored solutions to meet client needs.
              My background across various companies has sharpened my skills in analyzing and improving operational processes for better team productivity.
              I proactively implement optimizations and integrate feedback to enhance my work.
            </Quote>
          </FadeIn>
        </Container>
      </Section>
    </FadeIn>
  );
}
