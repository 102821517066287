import styled from 'styled-components';

export const CardContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: -20px;
  overflow: hidden;
  text-align: center;
`;

export const CardHeader = styled.div`
  padding: 16px 8px;
`;

export const CardItem = styled.div`
  padding: 5px;
`;

export const CardFooter = styled.div`
  background: var(--color-secondary);
  color: white;
  font-size: 0.85em;
  padding: 12px 6px;
`;

const defaultExport = {
  CardContainer,
  CardFooter,
  CardHeader,
  CardItem,
};

export default defaultExport;
