import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContainer, CardFooter, CardHeader, CardItem } from 'components/Cards';

const ExperienceCardContainer = styled(CardContainer)`
  cursor: pointer;
`;

const ExperienceCardHeader = styled(CardHeader)`
  background: var(--color-primary);
  color: white;
`;

const ExperienceCardFooter = styled(CardFooter)`
  background: var(--color-secondary);
`;

export default function ExperienceCard({
  company,
  date,
  description,
  location,
  title,
  type,
  ...restProps
}) {
  const titleIcon = type === 'employee' ? 'briefcase' : 'graduation-cap';

  return (
    <ExperienceCardContainer {...restProps}>
      <ExperienceCardHeader>
        {company}
      </ExperienceCardHeader>

      <CardItem>
        <FontAwesomeIcon icon={["fas", titleIcon]} />{' '}
        {title}
      </CardItem>

      <CardItem>
        <FontAwesomeIcon icon={["fas", "location-dot"]} />{' '}
        {location}
      </CardItem>

      <ExperienceCardFooter>
        {date}
      </ExperienceCardFooter>
    </ExperienceCardContainer>
  );
}
