import styled from 'styled-components';

const Quote = styled.pre`
  border-left: solid 2px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-shadow: -10px 0 15px -10px rgba(0, 0, 0, 0.5);
  line-height: 1.4;
  margin-bottom: 10px;
  padding: 8px 12px;
  text-align: justify;
  white-space: pre-line;
`;

export default Quote;
