import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import reportWebVitals from './reportWebVitals';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'

import 'styles/main.scss';

library.add(fab);
library.add(fas);

const rootDOMElement = document.getElementById('root');

ReactModal.setAppElement(rootDOMElement);

const root = ReactDOM.createRoot(rootDOMElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* Prevent default service worker that mess up with development and cache requests when it should not */
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration().then(
    (registration) => {
      !!registration && registration.unregister();
    }
  )
}
