import React, { createContext, useContext, useReducer } from 'react';

const NavigationContext = createContext();

export function useNavigation() {
  return useContext(NavigationContext);
}

export function NavigationProvider({ children }) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_ACTIVE':
        return {
          ...state,
          active: action.payload,
        };
      default:
        return state;
    }
  }, {
    active: 'profile',
  });

  return (
    <NavigationContext.Provider value={{ state, dispatch }}>
      {children}
    </NavigationContext.Provider>
  );
}

export default NavigationContext;

