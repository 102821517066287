import React from 'react';

export default function Clock({ refreshInterval = 1000, timezone }) {
  const [time, setTime] = React.useState(new Date());

  React.useEffect(() => {
    const tick = () => {
      setTime(new Date());
    };
    const interval = setInterval(tick, refreshInterval);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [refreshInterval]);

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone
  }).format(time);

  return formattedTime;
}
