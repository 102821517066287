import styled from 'styled-components';

const Title1 = styled.h1`
  color: var(--color-text);
  font-size: 8vmin;
  letter-spacing: 2px;
  line-height: 1.6;
  margin: 0;
`;

export default Title1;
