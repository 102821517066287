import IconText from 'components/IconText';
import Marquee from 'react-fast-marquee';
import Quote from 'components/semantic/Quote';
import React from 'react';
import ReactModal from 'react-modal';
import Skill from 'components/Skill';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ExperienceModal.scss';

const ModalContainer = styled.div`
  background-color: var(--color-background);
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
  width: 800px;

  @media (max-width: 768px) {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  background-color: var(--color-primary);
  color: white;
  padding: 20px 50px 20px 20px;
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const ModalContentList = styled.ul`
  border: solid 1px lightgray;
  border-radius: 10px;
`;

const ModalContentListItem = styled.li`
  border-bottom: 1px solid lightgray;
  padding: 10px 20px;

  &:last-child {
    border-bottom: none;
  }
`;

const ModalFooter = styled.div`
  background-color: var(--color-secondary);
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 20px;
`;

const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export default function ExperienceModal({
  company,
  date,
  description,
  isOpen,
  location,
  onClose,
  skills,
  tasks,
  title,
  type,
}) {
  const titleIcon = type === 'employee' ? 'briefcase' : 'graduation-cap';

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <ReactModal
      className="Modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="Overlay"
      preventScroll
      onAfterClose={onClose}
    >
      <ModalContainer>
        <ModalHeader>
          <FontAwesomeIcon icon={["fas", titleIcon]} />{' '}
          {company} &middot; {title}

          <ModalCloseButton onClick={onClose}>
            <FontAwesomeIcon icon="times" />
          </ModalCloseButton>
        </ModalHeader>

        <ModalContent>
          <Quote>
            {description}
          </Quote>

          {skills && !!skills.length && (
            <Marquee speed={125}>
              {skills.map((skill, index) => (<Skill {...skill} key={index} />))}
            </Marquee>
          )}

          <ModalContentList>
            {tasks.map((task, index) => (
              <ModalContentListItem key={index}>
                {task}
              </ModalContentListItem>
            ))}
          </ModalContentList>
        </ModalContent>

        <ModalFooter>
          <IconText icon="calendar-days">
            {date}
          </IconText>

          <IconText icon="location-dot">
            {location}
          </IconText>
        </ModalFooter>
      </ModalContainer>
    </ReactModal>
  );
}
