import Container from 'components/semantic/Container';
import FadeIn from 'components/animation/FadeIn';
import Marquee from 'react-fast-marquee';
import Quote from 'components/semantic/Quote';
import React from 'react';
import Section from 'components/Section';
import Skill from 'components/Skill';
import Tag from 'components/Tag';
import { Tab, Tabs } from 'components/Tabs';
import { Title2 } from 'components/semantic/Titles';

import skills from 'data/skills';

export default function SectionSkills(props) {
  const halfwayThrough = Math.floor(skills.length / 2)
  const arrayFirstHalf = skills.slice(0, halfwayThrough);
  const arraySecondHalf = skills.slice(halfwayThrough, skills.length);

  return (
    <FadeIn delay={250}>
      <Section {...props} name="skills">
        <Title2>
          Skills
        </Title2>

        <Container>
          <Tabs>
            <Tab name="Hard skills">
              <Marquee speed={125}>
                {arrayFirstHalf.map((skill) => (<Skill {...skill} key={skill.name} />))}
              </Marquee>

              <Marquee direction="right" speed={125}>
                {arraySecondHalf.map((skill) => (<Skill {...skill} key={skill.name} />))}
              </Marquee>
            </Tab>

            <Tab name="Soft skills">
              <Quote>
                <Tag>Communication</Tag>
                Possessing strong proficiency in asynchronous work processes, adept at conveying information through written notes and announcements across various communication channels tailored to different levels of urgency.
              </Quote>

              <Quote>
                <Tag>Investment</Tag>
                Proactively inclined to take initiatives, unfazed by the diffusion of responsibility when faced with unassigned tasks.
              </Quote>

              <Quote>
                <Tag>Feedback culture</Tag>
                Resilient to feedback, maintaining a receptive attitude towards constructive criticism and continuously striving for improvement.
              </Quote>

              <Quote>
                <Tag>Languages</Tag>
                Native French speaker with fluent written English skills and conversational spoken English, though slightly rusty. Possesses scholar knowledge of German.
              </Quote>
            </Tab>
          </Tabs>
        </Container>
      </Section>
    </FadeIn>
  );
}
