import Clock from 'components/Clock';
import FadeIn from 'components/animation/FadeIn';
import Link from 'components/semantic/Link';
import React from 'react';
import Section from 'components/Section';
import ShareButton from 'components/ShareButton';
import socialNetworks from 'data/socialNetworks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title2 } from 'components/semantic/Titles';

import './SectionContact.scss';

function FooterContactItem({ children, icon }) {
  return (
    <div className="footer-contact-item">
      <FontAwesomeIcon icon={["fas", icon]} />{' '}{children}
    </div>
  );
}

function FooterSocialNetworkItem({ children, name, url }) {
  return (
    <Link
      aria-label={`Please check out my social network ${name} using this link!`}
      href={url}
      tab
    >
      <FontAwesomeIcon icon={["fab", name]} />
      {children}
    </Link>
  );
}

const saintPierreGoogleMapsUrl = 'https://www.google.com/maps/place/Saint+Pierre+97410,+R%C3%A9union/@-21.3089255,55.4631419,13z/data=!3m1!4b1!4m6!3m5!1s0x21780a889018212b:0x1fcae5f80affe953!8m2!3d-21.3408775!4d55.4776663!16zL20vMDhzZ3c0?entry=ttu';

export default function SectionContact(props) {
  return (
    <Section {...props} name="contact" style={{ minHeight: 'auto' }}>
      <footer>
        <Title2 style={{ color: 'white' }}>
          Contact
        </Title2>

        <div className="footer-section footer-contact">
          <FooterContactItem icon="at">
            <Link href="mailto:lec.olivier@gmail.com" tab>
              lec.olivier@gmail.com
            </Link>
          </FooterContactItem>

          <FooterContactItem icon="location-dot">
            <Link href={saintPierreGoogleMapsUrl} tab>
              St Pierre, Reunion
            </Link>
          </FooterContactItem>

          <FooterContactItem icon="globe">
            {'UTC +4 : '}
            <Clock timezone="Indian/Reunion" />
          </FooterContactItem>

          <FooterContactItem icon="share">
            <ShareButton>
              Share this page
            </ShareButton>
          </FooterContactItem>
        </div>

        <div className="footer-section footer-socialNetworks">
          {socialNetworks.map((socialNetwork, index) => (
            <FadeIn className="footer-socialNetworks-item" delay={250 + 100 * index} key={socialNetwork.name}>
              <FooterSocialNetworkItem name={socialNetwork.name} url={socialNetwork.url} />
            </FadeIn>
          ))}
        </div>
      </footer >
    </Section>
  );
}
