import Link from 'components/semantic/Link';
import React from 'react';
import styled from 'styled-components';
import { CardContainer, CardFooter, CardHeader, CardItem } from 'components/Cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EducationCardHeader = styled(CardHeader)`
  background: var(--color-secondary);
`;

const EducationCardFooter = styled(CardFooter)`
  background: var(--color-tertiary);
`;

export default function Education({
  date,
  location,
  locationUrl,
  school,
  title,
  url,
  ...restProps
}) {
  return (
    <CardContainer {...restProps}>
      <EducationCardHeader>
        <Link href={locationUrl} inverted tab>
          {school}
        </Link>
      </EducationCardHeader>

      <CardItem>
        <FontAwesomeIcon icon={["fas", "graduation-cap"]} />{' '}
        {!url && title}
        {!!url && (
          <Link href={url} tab>
            {title}
          </Link>
        )}
      </CardItem>

      <CardItem>
        <FontAwesomeIcon icon={["fas", "location-dot"]} />{' '}
        {location}
      </CardItem>

      <EducationCardFooter>
        {date}
      </EducationCardFooter>
    </CardContainer>
  );
}
