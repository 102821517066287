import styled from 'styled-components';

const Tag = styled.span`
  background-color: var(--color-secondary);
  border-radius: 0.25rem;
  color: white;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  padding: 0.5rem 0.5rem 0.25rem;
  text-transform: uppercase;
`;

export default Tag;
