import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconText({
  category = 'fas',
  children,
  icon,
}) {
  return (
    <div>
      <FontAwesomeIcon icon={[category, icon]} />{' '}
      {children}
    </div>
  );
}
