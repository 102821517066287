import HomePage from 'layout/HomePage';
import { NavigationProvider } from 'layout/NavigationContext';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <NavigationProvider>
      <ToastContainer />
      <HomePage />
    </NavigationProvider>
  );
}

export default App;
